import request from '@/service/request'

export function list(data) {
  return request({
    url: '/externaladmin/productService/color/colorList',
    method: 'post',
    data
  })
}

export async function add(data) {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      url: '/externaladmin/productService/color/create',
      method: 'post',
      data
    }),
    'prim_color'
  )
}

export function read(data) {
  return request({
    url: `/api/prim_prod/prim_color/${data}/`,
    method: 'get',
    data
  })
}

export async function edit(data) {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      url: '/externaladmin/productService/color/update',
      method: 'post',
      data
    }),
    'prim_color'
  )
}

export async function del(id) {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      url: '/api/prim_prod/prim_color/' + id + '/',
      method: 'delete'
    }),
    'prim_color'
  )
}
