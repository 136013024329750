<template>
  <BaseDialog
    minHeight="200px"
    :dialogVisible.sync="dialog"
    width="600px"
    :title="title"
  >
    <template>
      <BaseForm
        ref="form"
        label-width="100px"
        :cols="formField"
        :form="form"
        :formVisible="dialog"
        v-loading="loading"
      >
        <template #color_valueSlot="{ scoped: { prop } }">
          <ColorSketchPicker
            style="width: 80%"
            v-model="form[prop]"
            :class="['sketch-picker']"
            :presetColors="[]"
            :disableAlpha="true"
          />
        </template>
      </BaseForm>
    </template>
    <template #footer>
      <el-button :loading="loading" type="primary" @click="doSubmit">
        确认
      </el-button>
      <LoadingBtn @click="cancel"> 取消 </LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import ColorSketchPicker from '@/components/colorSketchPicker'

import { colorsFiled as formField } from './field'
import { commonFromMixin } from '@/mixins'
import { add, edit } from '@/api/product/colorApi'

export default {
  mixins: [commonFromMixin],
  components: {
    ColorSketchPicker,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formField,
      form: this.data || {
        colorValue: '#ffffff',
      },
      loading: false,
    }
  },

  methods: {
    async doSubmit() {
      const valid = await this.validate()
      if (!valid) return
      if (this.isAdd) {
        this.doAdd()
      } else {
        this.doEdit()
      }
    },
    async doAdd() {
      this.loading = true
      try {
        let { colorName, colorValue } = this.form
        colorValue =
          typeof colorValue === 'string' ? colorValue : colorValue.hex

        const { code } = await add({
          colorName,
          colorValue,
        })
        if ($SUC({ code })) {
          this.success()
        }
      } catch (err) {}
      this.loading = false
    },

    async doEdit() {
      this.loading = true
      try {
        const { id } = this.data
        let { colorName, colorValue } = this.form
        colorValue =
          typeof colorValue === 'string' ? colorValue : colorValue.hex

        const { code } = await edit({
          id,
          colorName,
          colorValue,
        })
        if ($SUC({ code })) {
          this.success()
        }
      } catch (err) {}
      this.loading = false
    },
  },
}
</script>
